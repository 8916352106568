<template>
  <div class="page_msg container">
    <div class="header_gruop page_width flex_sb">
      <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
      <div class="header_title">About Us</div>
      <div></div>
    </div>

    <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto">
        <div class="service page_width">
            
            <p style="font-size: 18px;font-weight: bold;margin-bottom:20px;">About Us</p>
            <p style="margin-bottom:10px;">playsavefirst is an established global digital goods and services platform for gamers, which provides the best professional game solution for all the gamers. We sell professional and affordable direct game top-up.</p>

            <p style="margin-bottom:10px;">playsavefirst aims to provide more and more digital products & services for customers.</p>
            <p>Have fun shopping in playsavefirst.net and talk to us via Customer-service@playsave-first.com , we will respond soon.</p>

        </div>
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        toBack(){
            this.$router.back()
        }
    }
}
</script>

<style>

</style>